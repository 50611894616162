import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../css/privacyPolicy.css";

const PrivacyPolicy = ( { closeModal } ) =>
{
    const [ isBottom, setIsBottom ] = useState( false );
    const mainContentRef = useRef( null );

    useEffect( () =>
    {
        const handleScroll = () =>
        {
            const mainContent = mainContentRef.current;
            if ( mainContent )
            {
                const isScrolledToBottom = mainContent.scrollTop + mainContent.clientHeight >= mainContent.scrollHeight;
                setIsBottom( isScrolledToBottom ); // Enable button when at the bottom
            }
        };

        const mainContent = mainContentRef.current;
        if ( mainContent )
        {
            mainContent.addEventListener( 'scroll', handleScroll );
        }

        return () =>
        {
            if ( mainContent )
            {
                mainContent.removeEventListener( 'scroll', handleScroll );
            }
        };
    }, [] );

    const handleDoneReading = () =>
    {
        if ( isBottom )
        {
            closeModal(); // Close modal only when button is clicked
        }
    };

    const handleSidebarClick = ( id ) =>
    {
        const targetElement = document.getElementById( id );
        if ( targetElement )
        {
            targetElement.scrollIntoView( { behavior: "smooth" } );
        }
    };

    const sections = [
        "Pendahuluan",
        "Tentang Kebijakan ini",
        "Hak dan Preferensi anda",
        "Data Pribadi yang kami kumpulkan",
        "Tujuan pengumpulan data pribadi",
        "Membagikan data pribadi kepada Pihak Ketiga",
        "Penyimpanan dan penghapusan data",
        "Pengiriman data pribadi ke Negara lain",
        "Menjaga keamanan data pribadi anda",
        "Perubahan Kebijakan Privasi",
        "Cara menghubungi kami",
        "Persetujuan"
    ];

    const sectionContents = [
        `<p>Kami memahami pentingnya perlindungan Informasi Pribadi (sebagaimana didefinisikan dalam definisi Informasi Pribadi berdasarkan Peraturan Data Pribadi) dan berkomitmen untuk melindungi Informasi Pribadi.</p><br>
        <p>Jika Anda, berdasarkan Peraturan Data Pribadi, merupakan pihak yang di bawah umur, dalam pengampuan atau memerlukan wali, maka Anda diharuskan untuk menyertakan wali atau perwakilan Anda untuk membaca Kebijakan Privasi ini dan menyetujui penggunaan Anda atas produk dan layanan kami berdasarkan syarat dan ketentuan Kebijakan Privasi ini. Dalam kasus di mana persetujuan langsung dari wali atau perwakilan hukum Anda diperlukan, Anda harus mendapatkan persetujuan tersebut atas tanggung jawab Anda sendiri.</p><br>
        <p>Kebijakan Privasi ini merupakan bagian esensial dan tidak terpisahkan dengan produk atau layanan kami yang tanpanya produk atau layanan kami tidak akan berjalan dan berfungsi sebagaimana mestinya. Oleh karena itu, jika Anda tidak menyetujui Kebijakan Informasi Pribadi ini, atau jika wali atau perwakilan hukum Anda tidak setuju dengan Kebijakan Informasi Pribadi ini, Anda tidak dapat menggunakan produk atau layanan kami.</p><br>
        <p>Dengan anda mengisi formulir dan/atau mengakses di Laman Website ini, anda dengan sadar dan sukarela memberikan persetujuan kepada TAM atas data pribadi anda.</p><br>
        <p>Informasi ini akan menjelaskan maksud dan tujuan Kebijakan Privasi secara rinci di bawah ini.</p>`,

        `<p>Dari waktu ke waktu, kami dapat mengembangkan layanan baru atau menawarkan layanan tambahan. Apabila layanan baru atau layanan tambahan tersebut menyebabkan adanya perubahan yang material dari persetujuan awal dalam cara kami mengumpulkan atau memproses data pribadi anda, maka kami akan memberikan informasi lebih lanjut atau ketentuan atau kebijakan tambahan kepada anda melalui notifikasi pop up pada bagian mailbox profile akun di Laman Website dan meminta persetujuan kembali atas data pribadi anda yang akan kami kumpulkan, olah, analisa, gunakan untuk layanan baru atau layanan tambahan tersebut.</p><br>
        <p>Ketika kami memperkenalkan layanan baru atau layanan tambahan tersebut, layanan-layanan tersebut akan tunduk pada Kebijakan Privasi ini.</p><br>
        <p>Tujuan dari Kebijakan Privasi ini adalah untuk:</p>
        <ol>
            <li>Memastikan anda memahami data pribadi apa saja yang kami kumpulkan dari anda, alasan mengapa kami mengumpulkan, mengolah, menganalisa, menggunakan, menyimpan, memusnahkan, dan kepada siapa kami membagikannya;</li>
            <li>Menjelaskan cara kami menggunakan data pribadi yang anda bagikan dengan kami agar kami dapat memberikan pengalaman yang terbaik bagi anda ketika anda menggunakan Laman Website; dan</li>
            <li>Menjelaskan hak yang anda miliki terkait dengan data pribadi anda yang kami kumpulkan dan kami proses dan bagaimana kami akan melindungi privasi anda.</li>
        </ol>
        <p>Kami berharap Kebijakan Privasi ini dapat membantu anda memahami komitmen kami kepada anda. Untuk penjelasan lebih lanjut mengenai ketentuan-ketentuan yang kami gunakan dalam Kebijakan Privasi ini, anda dapat mengunjungi Pusat Privasi kami di Laman Website. Untuk informasi bagaimana menghubungi kami apabila anda memiliki pertanyaan seputar data pribadi anda, anda dapat merujuk ke <a href="#">Bagian 11: Cara Menghubungi kami</a> di bawah ini.</p><br>`,

        `<p>Kami dengan senang hati menawarkan keterbukaan untuk membantu Anda dalam memanfaatkan hak-hak tersebut. Kecuali jika dibatasi berdasarkan hukum yang berlaku, hak-hak yang dimiliki oleh Anda adalah:</p><br>
        <ul>
            <li>Hak untuk mengakses - hak untuk diberitahu mengenai, dan meminta akses terhadap, data pribadi Anda yang kami proses;</li>
            <li>Hak untuk memperbaiki - hak untuk meminta kami mengubah atau memperbarui data pribadi Anda apabila data tersebut tidak akurat atau tidak lengkap;</li>
            <li>Hak untuk menghapus - hak untuk meminta kami menghapus data pribadi Anda;</li>
            <li>Hak untuk membatasi - hak untuk meminta agar kami sementara atau seterusnya berhenti memproses seluruh atau sebagian data pribadi Anda;</li>
            <li>
                Hak untuk menolak -
                <ul>
                    <li>hak untuk, sewaktu-waktu, menolak pemrosesan data pribadi Anda oleh kami dengan alasan yang terkait dengan situasi khusus Anda;</li>
                    <li>hak untuk menolak pemrosesan data pribadi Anda oleh kami untuk tujuan pemasaran langsung;</li>
                </ul>
            </li>
            <li>Hak atas portabilitas data - hak untuk meminta salinan data pribadi Anda secara elektronik dan hak untuk mengirimkan data pribadi tersebut untuk digunakan pada layanan pihak lain; dan</li>
            <li>Hak untuk tidak tunduk pada pembuatan keputusan secara otomatis - hak untuk tidak tunduk pada suatu keputusan yang diambil hanya berdasarkan pembuatan keputusan secara otomatis, termasuk pembentukan profil (profiling), dimana keputusan tersebut dapat menyebabkan akibat hukum terhadap Anda atau menyebabkan akibat signifikan yang serupa.</li>
        </ul>
        <p>Agar Anda dapat mempelajari lebih jauh mengenai hak-hak tersebut, melaksanakan hak-hak tersebut dengan mudah, dan merekam pilihan Anda terkait dengan bagaimana kami menggunakan data pribadi Anda, kami menyediakan sumber-sumber berikut ini:</p><br>
        <ul>
            <li>
                <strong>Setelan Privasi (diakses melalui laman akun Anda)</strong> - Anda dapat menerapkan pilihan Anda mengenai pemrosesan data pribadi tertentu, dan fungsi otomatis "Unduh data Anda" untuk mengunduh informasi dasar mengenai akun dan penggunaan.
            </li>
            <li>
                <strong>Pusat Privasi</strong> - menyediakan lokasi pusat yang mudah dijangkau dimana Anda dapat memperoleh informasi lebih lanjut mengenai bagaimana kami menggunakan data pribadi Anda, hak-hak Anda terkait dengan data pribadi Anda, dan bagaimana menerapkan hak-hak tersebut.
            </li>
            <li>
                <strong>Kebijakan Cookies</strong> - memberikan Anda informasi lebih lanjut tentang bagaimana kami menggunakan cookies, termasuk untuk pengiklanan berdasarkan minat Anda. Anda juga dapat memperoleh informasi mengenai bagaimana Anda dapat mengatur preferensi cookie Anda dan untuk keluar dari jenis pelacakan tertentu.
            </li>
        </ul>`,

        `<div class="table-container">
        <p>Kami telah menjabarkan dalam tabel di bawah ini kategori data pribadi anda yang kami kumpulkan dan kami gunakan serta bagaimana kami mengumpulkannya:</p><br>
        <span class='subtitle'>Tabel di bawah ini menjelaskan data pribadi yang dikumpulkan ketika anda mendaftarkan diri pada Laman Website:</span>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="text-align:center">Kategori data pribadi</th>
                    <th style="text-align: center; vertical-align: middle;">Penjelasan kategori</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><strong>Data Pengguna</strong></td>
                    <td>
                        <p>Berikut ini adalah data pribadi yang diberikan oleh anda atau dikumpulkan oleh kami. Bergantung pada Data tersebut dapat meliputi nama pengguna, kota, domisili, alamat email, nomor telepon, preferensi dealer dan produk</p><br>
                        <p>Sebagian data pribadi yang akan kami minta untuk anda berikan diperlukan agar anda mendapatkan informasi lebih lanjut terkait Toyota. Anda juga memiliki pilihan untuk memberikan data pribadi tambahan kepada kami agar anda dapat lebih mempersonalisasi akun anda.</p><br>
                        <p>Data pribadi apa saja yang kami kumpulkan</p>
                    </td>
            </tbody>
        </table>
        <span class='subtitle'>Tabel di bawah ini menjelaskan data pribadi yang dikumpulkan ketika anda mengakses atau mengisi formulir di website:</span>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="text-align:center">Kategori data pribadi</th>
                    <th style="text-align: center; vertical-align: middle;">Penjelasan kategori</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="highlight"><strong>Data Penggunaan</strong></td>
                    <td>
                        Berikut ini adalah data pribadi yang dapat kami kumpulkan ketika anda mengakses dan/atau menggunakan Laman Website, meliputi:
                        <ul>
                            <li>Informasi mengenai interaksi anda pada Website kami, seperti hasil pencarian anda (termasuk tanggal dan waktu permintaan yang anda buat);</li>
                            <li>Kesimpulan mengenai ketertarikan dan preferensi anda dibuat berdasarkan akses anda di Website kami;</li>
                            <li>Data teknis tertentu, yang dapat meliputi:
                                <ul>
                                    <li>Informasi URL;</li>
                                    <li>Pengidentifikasi online termasuk data cookie dan alamat IP;</li>
                                    <li>Informasi mengenai tipe perangkat yang anda gunakan, seperti ID unik perangkat, tipe koneksi jaringan, tipe browser, sistem operasi;</li>
                                    <li>Atribut perangkat dari perangkat pada jaringan wifi anda yang dapat tersambung pada Laman Website;</li>
                                    <li>Perkiraan lokasi anda, yang dapat diambil atau disimpulkan dari data teknis tertentu untuk mematuhi ketentuan geografis dalam perjanjian lisensi kami, dan memberikan konten dan iklan yang dipersonalisasi; dan</li>
                                    <li>Data sensor bergerak yang diperlukan untuk menyediakan fitur spesifik dari Laman Website kepada anda.</li>
                                </ul>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td class="highlight"><strong>Data Verifikasi Pelanggan</strong></td>
                    <td>Untuk akses laman Website kami, kami dapat menggunakan aplikasi pemetaan pihak ketiga (seperti Google Maps) dan/atau layanan lokasi perangkat anda untuk membantu anda memverifikasi alamat anda.</td>
                </tr>
            </tbody>
        </table>
        <span class='subtitle'>*Tabel di bawah ini menjelaskan data pribadi yang anda pilih untuk dibagikan dengan kami agar kami dapat menyediakan fitur/fungsionalitas tambahan kepada anda*</span>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="text-align:center">Kategori data pribadi</th>
                    <th style="text-align: center; vertical-align: middle;">Penjelasan kategori</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Data Pembayaran dan Pembelian</td>
                    <td>
                        <p>Kami dapat mengumpulkan data pribadi tertentu apabila anda mendaftar pada Program Percobaan (Trial) atau membeli Program Berlangganan Berbayar kami manapun (sebagaimana didefinisikan dalam Syarat dan Ketentuan Penggunaan) atau melakukan pembelian lain melalui Laman Website. Data pribadi seperti apa yang dikumpulkan akan berbeda-beda, tergantung pada metode pembayaran (misalnya, secara langsung melalui penyedia jaringan telepon genggam atau dengan tagihan) namun akan meliputi informasi seperti:</p><br>
                        <ul>
                            <li>Nama</li>
                            <li>Kota Domisili</li>
                            <li>Alamat email</li>
                            <li>Nomor telepon genggam</li>
                            <li>Preferensi produk dan preferensi dealer</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Data Kontes, Survey dan Undian</td>
                    <td>Ketika anda selesai mengisi suatu formulir, menjawab suatu survei atau kuesioner, atau mengikuti suatu kontes, kami mengumpulkan data pribadi yang anda berikan.</td>
                </tr>
            </tbody>
        </table>
        <span class='subtitle'>*Tabel di bawah ini menjelaskan data pribadi yang dikumpulkan dari sumber pihak ketiga*</span><br><br>
        <p>Kami mengumpulkan data pribadi mengenai anda dari berbagai pihak ketiga. Sumber pihak ketiga ini berbeda-beda dari waktu ke waktu dan meliputi berikut ini:</p>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="text-align:center">Kategori sumber pihak ketiga</th>
                    <th style="text-align: center; vertical-align: middle;">Penjelasan kategori</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mitra layanan teknis</td>
                    <td>Kami bekerja sama dengan mitra layanan teknis yang menyediakan data tertentu untuk kami, seperti pemetaan alamat IP hingga data perkiraan lokasi (misalnya, kota, negara), agar kami dapat memberikan informasi komprehensif, konten dan fitur-fitur.</td>
                </tr>
                <tr>
                    <td>Pemasang iklan dan mitra iklan lainnya</td>
                    <td>Kami mungkin memperoleh data tertentu mengenai anda, seperti id cookie, id perangkat bergerak, atau alamat email, dan kesimpulan mengenai minat dan preferensi anda dari pemasang iklan dan mitra iklan tertentu yang memungkinkan kami untuk memberikan iklan-iklan yang lebih relevan dan mengukur efektivitas mereka.</td>
                </tr>
            </tbody>
        </table>
    </div>`,

    `<p>Ketika anda menggunakan atau berinteraksi dengan Laman Website, kami menggunakan berbagai macam teknologi untuk memproses data pribadi anda yang kami kumpulkan untuk berbagai alasan. Kami jelaskan dalam tabel di bawah ini alasan-alasan mengapa kami memproses data pribadi anda, dasar hukum terkait yang kami jadikan acuan agar kami dapat secara sah memproses data pribadi anda, dan kategori data pribadi (terdapat dalam <a href="#" style="text-decoration:underline;">Bagian 4 “Data pribadi anda yang kami kumpulkan”</a>) yang digunakan untuk tujuan berikut ini:</p><br>
    <ol>
        <li>Untuk menangani penerimaan pesanan, pemrosesan penyelesaian, pengiriman, layanan purna jual, perbaikan, produk dan layanan, dll.</li>
        <li>Untuk menghubungi pelanggan.</li>
        <li>Untuk menanggapi pertanyaan atau permintaan dari pelanggan dan mengkonfirmasi identitas pelanggan.</li>
        <li>Untuk mengoperasikan, merekam, dan mengumumkan acara terkait produk dan layanan kami.</li>
        <li>Untuk pengumuman, iklan dan kegiatan promosi mengenai produk dan layanan kami (termasuk distribusi buletin surel dan pengumuman).</li>
        <li>Untuk desain, penelitian, pengembangan atau peningkatan produk/layanan serta riset dan analisis pasar.</li>
        <li>Untuk terlibat di dalam setiap bisnis tambahan atau terkait dengan hal-hal di atas.</li>
    </ol>
    </div>`,
    `<p>Data pribadi anda akan TAM bagikan kepada Pihak Ketiga mana pun untuk melaksanakan kegiatan operasional usaha kami sehari-hari dan untuk memungkinkan kami tetap mempertahankan dan menyediakan layanan informasi kepada anda.</p><br>
    <span style="font-style:italic; font-weight:bold">Informasi yang dapat kami bagikan kepada Pihak Ketiga</span><br><br>
    <p>TAM akan mengungkapkan atau memberikan Informasi Pribadi kepada Pihak Ketiga dengan tetap memperhatikan hal-hal sebagai berikut:</p><br>
    <ol>
        <li>Pengungkapan dilakukan dengan persetujuan dari pelanggan.</li>
        <li>Pengungkapan atau penyerahan dilakukan sejauh yang dipersyaratkan oleh hukum dan peraturan perundang-undangan yang berlaku.</li>
        <li>Pengungkapan dilakukan dengan cara yang tidak memungkinkan untuk mengidentifikasikan pelanggan individu, seperti dengan cara data statistik.</li>
        <li>Pengungkapan atau penyediaan dilakukan untuk Pihak Ketiga sejauh yang diperlukan untuk mencapai tujuan penggunaan sebagaimana ditentukan di dalam <strong>Bagian 5 : Untuk tujuan apa kami menggunakan data pribadi anda.</strong> Dalam hal ini, kami akan melakukan semua pengawasan yang diperlukan dan akan memastikan Pihak Ketiga mengambil langkah-langkah pencegahan seperti membuat suatu perjanjian dengan Pihak Ketiga tentang penanganan Informasi Pribadi.</li>
    </ol>`,

    `<p>Kami menyimpan data pribadi anda dengan cara enkripsi untuk tujuan bisnis yang sah dan penting, seperti menjaga layanan Laman Website kami, membuat keputusan bisnis berdasarkan data dan akses pada website kami, mematuhi kewajiban hukum kami, dan untuk menyelesaikan sengketa. Kami menyimpan sebagian dari data pribadi anda selama paling singkat 5 (lima) tahun dan paling lama sesuai dengan ketentuan Peraturan Data Pribadi.</p><br>
    <p>Apabila anda meminta, kami akan menghapus data Pribadi anda agar data tersebut tidak lagi mengidentifikasi anda, kecuali apabila kami secara hukum diperbolehkan atau disyaratkan untuk menyimpan data pribadi tertentu atau menyimpan data dengan cara menganonimkan data atau menerapkan pseudonomisasi, termasuk pada situasi berikut ini:</p><br>
    <ul>
        <li>Apabila kami perlu untuk menyimpan data pibadi untuk memenuhi kewajiban hukum, pajak, audit, dan akuntansi kami, kami akan menyimpan data pribadi yang diperlukan selama jangka waktu yang disyaratkan oleh hukum yang berlaku; dan/atau,</li>
        <li>Apabila diperlukan untuk kepentingan bisnis kami yang sah, seperti pencegahan tindak pidana pencucian uang atau untuk menjaga keamanan para pengguna kami.</li>
    </ul>`,

    `<p>Kami akan membagikan data pribadi anda secara global kepada Pihak Ketiga untuk melaksanakan kegiatan-kegiatan dan mencapai tujuan yang disebutkan dalam Kebijakan Privasi ini. Kami juga dapat mensubkontrakkan pemrosesan kepada, atau membagikan data pribadi anda kepada, pihak ketiga yang berlokasi di negara selain negara anda. Dengan demikian, data pribadi anda mungkin tunduk pada hukum privasi yang berbeda dengan yang berlaku di negara anda.</p><br>
    <p>Dalam hal tersebut, kami akan memastikan bahwa pengiriman data pribadi anda dilaksanakan sesuai dengan hukum privasi yang berlaku dan, khususnya, langkah-langkah kontraktual.</p><br>
    <p>Untuk penjelasan lebih lanjut mengenai langkah-langkah keamanan yang kami gunakan untuk melindungi data pribadi anda, anda dapat merujuk ke <a href="#" style="text-decoration:underline">Bagian 9 'Menjaga keamanan data pribadi anda'</a> dari Kebijakan ini.</p>`,

    `<p>Kami berkomitmen untuk melindungi data pribadi para pengunjung Laman Website. Kami menerapkan langkah-langkah teknis yang sepatutnya untuk membantu melindungi keamanan data pribadi anda; namun, harap dipahami bahwa tidak ada sistem yang akan sepenuhnya aman. Kami telah menerapkan berbagai kebijakan, termasuk kebijakan pseudonimisasi, enkripsi, akses dan penyimpanan untuk menjaga data pribadi dari akses yang tidak sah dan penyimpanan data pribadi yang tidak diperlukan dalam sistem kami.</p>`,

    `<p>Kami secara berkala akan menyesuaikan Kebijakan ini agar tetap sejalan dengan Peraturan yang berlaku. Kami akan memberitahukan kepada anda mengenai perubahan tersebut.</p><br>
    <p>Dengan demikian, pastikan anda membaca secara seksama pemberitahuan tersebut.</p><br>
    <p>Apabila anda ingin mengetahui lebih lanjut mengenai Kebijakan ini dan bagaimana kami menggunakan data pribadi anda, anda dapat mengunjungi <a href="#" style="text-decoration:underline">Pusat Privasi</a> di https://www.toyota.astra.co.id/privacy-policy untuk mendapatkan informasi lebih lanjut.</p>`,

    `<p>Terima kasih telah membaca Kebijakan kami. Apabila anda memiliki pertanyaan terkait dengan Kebijakan ini, anda dapat menghubungi Call Center kami dengan menggunakan formulir 'Hubungi Kami' atau melalui email ke alamat berikut ini:</p>
    <div class="contact-info">
        <p><strong>Layanan 24 Jam</strong></p>
        <p><strong>Telp : 1500 315</strong></p>
        <p><strong>Email : <span class="email">customer-care@toyota.astra.co.id</strong></p>
    </div>`,

    `<p>Dengan ini, saya secara sadar, sukarela dan dengan telah membaca seksama dan mempelajari hal-hal yang diuraikan dalam Kebijakan Privasi ini, mengetahui, mengakui dan menyetujui hal-hal yang disebutkan dalam Kebijakan Privasi dalam bentuk tanda tangan dan/atau dalam bentuk “klik accept/ya” di dalam sistem elektronik yang dimiliki/disediakan oleh kami.</p>`
    ];

    return (
        <div className="card">
            <div
                className="card-header"
                style={ {
                    backgroundImage: `url('/modalbk.png')`, // Path to the image in the public folder
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    color: 'white',
                    textAlign: 'left',
                    padding: '20px',
                    fontSize: '22px',
                    position: 'relative',
                    overflow: 'hidden',
                } }
            >
                <div
                    style={ {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                        zIndex: 1
                    } }>
                </div>

                <div
                    className="header"
                    style={ {
                        position: 'relative',
                        zIndex: 2
                    } }
                >
                    Kebijakan Privasi Laman Situs Website Toyota
                </div>
            </div>


            <div className="card-body">
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <p className="text-center my-2">
                                    Kebijakan Privasi ini berlaku sejak <span className="fw-bolder">26 Agustus 2021</span>, terakhir diperbaharui tertanggal <span className='fw-bolder'>01 Desember 2022</span>
                                </p>
                                <div className="col-12 round-back mt-3">
                                    <div className="row">
                                        {/* Sidebar only visible on medium screens and larger */ }
                                        <div className="col-md-4 d-none d-md-block sidebar">
                                            <ul>
                                                { sections.map( ( item, index ) => (
                                                    <li key={ index } onClick={ () => handleSidebarClick( `section${ index + 1 }` ) }>
                                                        <span className="number">{ index + 1 }.</span>
                                                        <span className="item-text">{ item }</span>
                                                    </li>
                                                ) ) }
                                            </ul>
                                        </div>
                                        {/* Main content takes full width on mobile and adjusts on larger screens */ }
                                        <div className={ `main-content ${ sections.length > 0 ? 'col-md-8 col-12' : 'col-12' }` } ref={ mainContentRef } style={ { overflowY: 'scroll', maxHeight: '500px' } }>
                                            { sections.map( ( section, index ) => (
                                                <div key={ index } id={ `section${ index + 1 }` } className="section">
                                                    <b className='title-text'>{ index + 1 }. { section }</b>
                                                    <div className='content-text mt-3' dangerouslySetInnerHTML={ { __html: sectionContents[ index ] } } />
                                                </div>
                                            ) ) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer text-center mt-3">
                                <button className="btn btn-done" onClick={ handleDoneReading } disabled={ !isBottom }>
                                    Done Reading
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
